const EContactUsStatusDataSource = {

    NEW:            { "id": 1,"name": "New","description": null },
    READED:         { "id": 2,"name": "Readed","description": null },
    RESPONDED:      { "id": 4,"name": "Responded","description": null },
    FLAGED:         { "id": 8,"name": "Flaged","description": null },
    KEEP_AT_TOP:    { "id": 16,"name": "KeepAtTop","description": null },
    DELETED:        { "id": 32,"name": "Deleted","description": null }

}

enum EContactUsStatus {
    
    NEW             = 1,
    READED          = 2,
    RESPONDED       = 4,
    FLAGED          = 8,
    KEEP_AT_TOP     = 16,
    DELETED         = 32
    
}


export default EContactUsStatusDataSource;
export {

    EContactUsStatus

}
